<template>
  <div class="container">
    <Header>
      <template v-slot:title>
        <span>{{ $t('createReservation') }}</span>
      </template>
    </Header>
    <div class="main">
      <div class="reservation">
        <div class="list">
          <div class="list-item">
            <van-row type="flex" gutter="8">
              <van-col span="8">
                <van-image height="100" lazy-load fit="cover" :src="hotel.images && hotel.images[0] || '#'" />
              </van-col>
              <van-col span="16">
                <div class="hotel-name">{{ hotel.name }}</div>
                <div class="room-type-name">{{ roomType.name }}</div>
                <div class="breakfast">{{ roomType.breakfast }}</div>
              </van-col>
            </van-row>

            <van-divider />
          </div>
        </div>

        <div class="date">
          <van-row type="flex">
            <van-col span="8" style="text-align: center;">
              <div class="arrival">
                <div>{{ $t('checkin') }}</div>
                <div>{{ reservation.arrival }}</div>
              </div>
            </van-col>
            <van-col span="8" style="display: flex; flex-direction: row;  align-items: center; justify-content: center;">
              <div class="nights">
                {{ nights }} {{ $t('nights') }}
              </div>
            </van-col>
            <van-col span="8" style="text-align: center;">
              <div class="departure">
                <div>{{ $t('checkout') }}</div>
                <div>{{ reservation.departure }}</div>
              </div>
            </van-col>
          </van-row>
        </div>

        <van-divider />

        <div class="guests">
          <van-row type="flex">
            <van-col span="12">{{ $t('guests') }}</van-col>
            <van-col span="12" style="display: flex; flex-direction: row; justify-content: flex-end;">
              <van-stepper v-model="reservation.guests" integer :min="1" :max="10" />
            </van-col>
          </van-row>
        </div>
        <van-divider />

        <div class="block">
          <h2>{{ $t('yourInformation') }}</h2>
          <van-cell-group>
            <van-field
              v-model="reservation.contact_number"
              label-width="100px"
              :label="$t('mobileNumber')"
              :placeholder="$t('yourMobileNumber')"
              :error="errors[0].error"
              :error-message="errors[0].message"
              maxlength="50"
            />
            <van-field
              v-model="reservation.email"
              label-width="100px"
              :label="$t('email')"
              :placeholder="$t('yourEmail')"
              :error="errors[1].error"
              :error-message="errors[1].message"
              maxlength="50"
            />
            <p style="color: red;">{{ $t('emailMessage') }}</p>
            <van-field
              v-model="reservation.last"
              label-width="100px"
              :label="$t('lastName')"
              :placeholder="$t('yourLastName')"
              :error="errors[3].error"
              :error-message="errors[3].message"
              maxlength="50"
            />
            <van-field
              v-model="reservation.first"
              label-width="100px"
              :label="$t('firstName')"
              :placeholder="$t('yourFirstName')"
              :error="errors[2].error"
              :error-message="errors[2].message"
              maxlength="50"
            />
            <van-field
              :value="$t(reservation.gender)"
              label-width="100px"
              :label="$t('gender')"
              readonly
              @click="showGender = true"
            />

            <van-action-sheet
              v-model="showGender"
              :actions="genders"
              :cancel-text="$t('cancel')"
              close-on-click-action
              @select="confirmGender"
            />
            <van-field
              :value="idTypeLabel"
              label-width="100px"
              :label="$t('idType')"
              readonly
              @click="showIdType = true"
            />

            <van-action-sheet
              v-model="showIdType"
              :actions="idTypes"
              :cancel-text="$t('cancel')"
              close-on-click-action
              @select="confirmIdType"
            />
            <van-field
              v-model="reservation.id_number"
              label-width="100px"
              :label="$t('idNumber')"
              :placeholder="$t('yourIdNumber')"
              :error="errors[4].error"
              :error-message="errors[4].message"
              maxlength="50"
            />
            <van-field
              v-model="reservation.accompany"
              label-width="100px"
              :label="$t('accompany')"
              :placeholder="$t('accompanyNames')"
              maxlength="50"
            />

            <van-field
              :value="arrivalTimeLabel"
              label-width="100px"
              :label="$t('arrivalTime')"
              readonly
              @click="showArrivalTime = true"
            />
            <van-action-sheet
              v-model="showArrivalTime"
              :actions="reservationTimes"
              :cancel-text="$t('cancel')"
              close-on-click-action
              @select="confirmArrivalTime"
            />

            <van-field
              :value="departureTimeLabel"
              label-width="100px"
              :label="$t('departureTime')"
              readonly
              @click="showDepartureTime = true"
            />
            <van-action-sheet
              v-model="showDepartureTime"
              :actions="reservationTimes"
              :cancel-text="$t('cancel')"
              close-on-click-action
              @select="confirmDepartureTime"
            />
          </van-cell-group>
        </div>

        <div class="block">
          <h2>{{ $t('remark') }}</h2>
          <van-field
            v-model="reservation.remark"
            rows="2"
            autosize
            type="textarea"
            maxlength="100"
            :placeholder="$t('reservationRemark')"
            show-word-limit
            style="border: 1px solid #dedede;"
          />
        </div>
        <van-divider />

        <div style="display: flex; flex-direction: row; align-items: center;">
          <van-checkbox v-model="accepted" shape="square"></van-checkbox>
          <span style="padding-left: 10px;">{{ $t('readAndAccept') }}</span>
          <span class="policy" @click="showPolicy = true">《{{ $t('cancellationPolicies') }}》</span>
        </div>
        <van-popup v-model="showPolicy" closeable :style="{ height: '60%', width: '80%' }">
          <div style="padding: 40px 20px 20px 20px;">
            <h4 style="text-align: center;">
              {{ $t('cancellationPolicies') }}:
            </h4>
            <ul>
              <li v-for="(item, index) of cancellationPolicies" :key="index">
                {{ item.description }}
              </li>
            </ul>
          </div>
        </van-popup>
      </div>
    </div>

    <div class="footer">
      <div>{{ $t('total') }}</div>
      <div class="rate" style="flex: 1; text-align: center;">{{ hotel.currency_code }}{{ reservation.total_amount | formatNumber }}</div>
      <div style="width: 50px; text-decoration: underline;" @click="showDetail = !showDetail">{{ $t('detail') }}</div>
      <div>
        <van-button
          type="warning"
          size="large"
          style="border-radius: 0; width: 120px;"
          @click="save"
          :loading="loading"
        >
          {{ $t('bookNow') }}
        </van-button>
      </div>
    </div>
    <van-popup v-model="showDetail" position="bottom" :overlay="false" class="padding-bottom">
      <van-cell-group>
        <van-cell v-for="(item, index) of details" :key="index" :title="item.reservation_date" :value="hotel.currency_code+formatNumber(item.rate_amount)" :get-container="getContainer" />
      </van-cell-group>
    </van-popup>
    <van-overlay :show="showDetail" @click="showDetail = false" />
  </div>
</template>

<script>
import _ from 'lodash'
import dayjs from 'dayjs'
import Header from '@/components/Header'
import Hotel from '@/models/hotel'
import Reservation from '@/models/reservation'
import RoomType from '@/models/roomType'
import { getDates, reservationTimes } from '@/utils'
import { formatNumber, getLabel } from '@/filters'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isIdentityCard from 'validator/lib/isIdentityCard'
import { Notify } from 'vant'

export default {
  name: 'Reservation',
  components: {
    Header
  },
  data () {
    return {
      showGender: false,
      showIdType: false,
      showArrivalTime: false,
      showDepartureTime: false,
      showPolicy: false,
      showDetail: false,
      accepted: true,
      loading: false,
      errors: new Array(6).fill({}).map(e => ({ error: false, message: null })),
      hotel: new Hotel(),
      roomType: new RoomType(),
      cancellationPolicies: [],
      idTypes: [],
      reservationTimes,
      reservation: new Reservation({
        hotel_id: this.$route.params.hotelId,
        room_type_id: this.$route.params.roomTypeId,
        arrival: this.$route.params.arrival,
        departure: this.$route.params.departure,
        guests: this.$route.params.guests
      })
    }
  },
  computed: {
    nights () {
      if (this.reservation.arrival && this.reservation.departure) {
        return dayjs(this.reservation.departure).diff(dayjs(this.reservation.arrival), 'day')
      }

      return 0
    },
    genders () {
      return [
        { code: 'Male', name: this.$t('Male') },
        { code: 'Female', name: this.$t('Female') }
      ]
    },
    idTypeLabel () {
      if (this.reservation.id_type) {
        const idType = _.find(this.idTypes, { code: this.reservation.id_type })
        if (idType) {
          return idType.name
        }
      }

      return this.reservation.id_type
    },
    arrivalTimeLabel () {
      return getLabel(_.find(reservationTimes, { code: this.reservation.arrival_time }))
    },
    departureTimeLabel () {
      return getLabel(_.find(reservationTimes, { code: this.reservation.departure_time }))
    },
    details () {
      const dates = getDates(this.reservation.arrival, this.reservation.departure)
      if (dates.length > 1) {
        dates.pop()
      }

      return dates.map(e => ({
        reservation_date: e,
        rate_amount: this.roomType.rate_amount
      }))
    }
  },
  async mounted () {
    try {
      const response = await this.$axios.get('/id-type', {
        params: {
          sort: 'sequence',
          search: 'enable==1'
        }
      })
      this.idTypes = response.data.rows.map(row => {
        row.name = this.$store.state.language === 'en' ? row.name : row.name_cn
        row.disabled = !row.enable
        return row
      })
      const res = await this.$axios.get(`/invitation/hotel-detail/${this.$route.params.hotelId}/room-type-detail/${this.$route.params.roomTypeId}`)
      Object.assign(this.hotel, res.data.hotel)
      Object.assign(this.roomType, res.data.roomType)
      this.cancellationPolicies = res.data.cancellationPolicies
      this.reservation.total_amount = (parseInt(this.nights) * this.roomType.rate_amount).toFixed(2)
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    formatNumber,
    getContainer () {
      return document.querySelector('.main')
    },
    confirmGender (value) {
      this.reservation.gender = value.code
      this.showGender = false
    },
    confirmIdType (value) {
      this.reservation.id_type = value.code
      this.showIdType = false
    },
    confirmArrivalTime (value) {
      this.reservation.arrival_time = value.code
      this.showIdType = false
    },
    confirmDepartureTime (value) {
      this.reservation.departure_time = value.code
      this.showIdType = false
    },
    validate () {
      if (isEmpty(this.reservation.contact_number)) {
        this.errors[0].error = true
        this.errors[0].message = this.$t('contact number is required')
      } else if (!isMobilePhone(this.reservation.contact_number)) {
        this.errors[0].error = true
        this.errors[0].message = this.$t('invalid mobile phone number')
      } else {
        this.errors[0].error = false
        this.errors[0].message = null
      }

      if (isEmpty(this.reservation.email)) {
        this.errors[1].error = true
        this.errors[1].message = this.$t('email is required')
      } else if (!isEmail(this.reservation.email)) {
        this.errors[1].error = true
        this.errors[1].message = this.$t('invalid email format')
      } else {
        this.errors[1].error = false
        this.errors[1].message = null
      }

      if (isEmpty(this.reservation.first)) {
        this.errors[2].error = true
        this.errors[2].message = this.$t('first name is required')
      } else {
        this.errors[2].error = false
        this.errors[2].message = null
      }

      if (isEmpty(this.reservation.last)) {
        this.errors[3].error = true
        this.errors[3].message = this.$t('last name is required')
      } else {
        this.errors[3].error = false
        this.errors[3].message = null
      }

      if (isEmpty(this.reservation.id_number)) {
        this.errors[4].error = true
        this.errors[4].message = this.$t('id number is required')
      } else if (this.reservation.id_type === 'ID' && !isIdentityCard(this.reservation.id_number, ['zh-CN'])) {
        this.errors[4].error = true
        this.errors[4].message = this.$t('invalid id number')
      } else {
        this.errors[4].error = false
        this.errors[4].message = null
      }

      return !_.find(this.errors, { error: true })
    },
    async save () {
      if (this.validate()) {
        if (!this.accepted) {
          Notify(this.$t('please read the cancellation policy carefully and check accepted'))
          return
        }

        if (this.nights < 2) {
          Notify(this.$t('reservationNightsMessage'))
          return
        }
        // 提交预订
        try {
          this.loading = true
          const res = await this.$axios.post('/reservation', this.reservation)

          this.$router.push({
            name: 'Payment',
            params: { reservationId: res.data.id }
          })
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
  padding-bottom: 50px;
}

.reservation {
  padding: 1rem;
}

.date {
  padding: 14px 0;
  .nights {
    border: 1px solid #CCCCCC;
    padding: 5px 10px;
    border-radius: 50%;
    color: #7C7C7C;
  }
}

.guests {
  .van-row {
    padding-top: 10px;
  }
  padding-bottom: 10px;
}

.hotel-name {
  margin-top: .3rem;
  font-size: 1.2rem;
}

.room-type-name {
  margin-top: .3rem;
  font-size: 1rem;
  color: #2766ba;
}

.breakfast {
  color: #5c5c5c;
  margin-top: .3rem;
}

.policy {
  color: #2766ba;
}

ul {
  list-style: inside;
  li {
    color: #5c5c5c;
    margin-bottom: 10px;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1em;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  border-top: 1px solid #dedede;
  z-index: 10;
}

.padding-bottom.van-popup {
  bottom: 50px;
}
</style>
